import React from "react";
import { SnackbarContent, CustomContentProps } from "notistack";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useTheme } from "@guberno/utils/theme";

declare module "notistack" {
  interface VariantOverrides {
    // removes the `warning` variant
    warning: false;
    // adds `myCustomVariant` variant
    myCustomVariant: true;
    // adds `reportComplete` variant and specifies the
    // "extra" props it takes in options of `enqueueSnackbar`
    smallCheckMark: {};
  }
}

export const SmallCheckMark = React.forwardRef<
  HTMLDivElement,
  CustomContentProps
>((props, ref) => {
  const {
    // You have access to notistack props and options 👇🏼
    id,
    message,
    // as well as your own custom props 👇🏼

    ...other
  } = props;
  const theme = useTheme();
  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <IoIosCheckmarkCircle color={theme.appColors.secondary} size={20} />
    </SnackbarContent>
  );
});

SmallCheckMark.displayName = "SmallCheckMark";
